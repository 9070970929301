/* CarouselWithOverlayText.css */

@import url("https://fonts.googleapis.com/css?family=Tilt+Prism");
.slick-slide {
  margin: 0 !important;
  padding: 0 !important;
}

.carousel-container {
  border-radius: 0;
  margin: 86px 0 0 0;
  height: calc(100vh - 86px);
}

.carousel-container .slide {
  height: 100%;
  color: black;
  padding: 0;
  margin: 0;
  background-color: white; 
}
.carousel-container .slide .slide-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slide-info h1 {
  font-size: 68px;
  font-weight: 500;
  font-family: "Tilt Prism";
  letter-spacing: 0.5em;
  text-shadow: 5px 0px 4px rgba(0,0,0,0.5);
}

.slide-info p {
  font-size: 24px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .carousel-container .slide .slide-info{
    margin: 0;
  }
  .slide-info h1 {
    letter-spacing: 0.1em;
    font-size: 54px;
  }
  .slide-info p {
    font-size: 18px;
    letter-spacing: 0em;
  }
}

.carousel-container .slide .slide-img {
  position: relative;
}

.slide-img .img-top {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  border-bottom: 21vw solid transparent;
  border-right: 49vw solid rgba(0, 0, 0, 0.3);
}

.slide-img .img-bottom {
  height: 50%;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 21vw solid transparent;
  border-right: 49vw solid rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  
  .carousel-container .slide .slide-img {
    background-size: 100% 100% !important;
  }
  .slide-img .img-top {
    border: 0 solid transparent;
  }
  .slide-img .img-bottom {
    border: 0 solid transparent;
  }
}
