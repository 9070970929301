#services {
  background-color: whitesmoke;
  padding: 4em;
}

@media only screen and (max-width: 768px) {
  [class*="col-"] {
    margin-bottom: 1rem;
  }
  #services {
    padding: 1em;
  }
}

#services .card-padding {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
#services .card-padding .service-title {
  font-size: 36px;
  font-variant: small-caps;
}

@media only screen and (max-width: 972px) {
  #services .card-padding {
    height: 200px;
  }
  #services .card-padding .service-title{
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  #services .row:nth-child(3) {
    margin-top: -3em;
  }
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.gradient-bg {
  height: 200px;
  background: linear-gradient(45deg, #d73a1e, #0d6efd);
}

.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}