html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./imgs/groovepaper.png");
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import "bootstrap/dist/css/bootstrap.min.css";

/* Common */

.line-wrap {
  display: flex;
  justify-content: center;
  line-height: 0.5;
  margin: 1em 0 1em 0;
}

.line-wrap span {
  display: inline-block;
  position: relative;
}

.line-wrap span:before,
.line-wrap span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 2px solid #e44d26;
  border-top: 2px solid #e44d26;
  top: 10px;
  width: 200px;
}
.line-wrap span:before {
  right: 100%;
  margin-right: 5%;
}
.line-wrap span:after {
  left: 100%;
  margin-left: 5%;
}

@media only screen and (max-width: 768px) {
  .section-header {
    margin-bottom: 1em;
  }
  .line-wrap span:before,
  .line-wrap span:after {
    width: 90px;
  }
}
/* Common */
