.contact{
  background-color:whitesmoke;
  padding: 4em;
}
.contact-main .info {
  width: 100%;
}
.contact-main .info span{
    margin-right: 20px;
}

.contact-main .info .icon {
  font-size: 20px;
  background: white;
  color: #151515;
  float: left;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;
}
.contact-main .info .icon:hover{
    background: #0d6efd;
    border: none;
    border-radius: 0 12px 0 12px;
}
.contact-main .info h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #151515;
}

.contact-main .info p {
  margin-bottom: 0;
  font-size: 14px;
  color: #484848;
}

.contact-main .email a{
  color: black;
  text-decoration: none;
}
.contact-main .info .email,
.contact-main .info .address {
  margin-top: 40px;
}

.contact-main input{
    padding: 10px;
}

.btn-send{
  margin: 1em 0;
}
