.footer{
    background-color: white;
    padding: 4em 4em 0 4em;
}

.social-media-icons{
    display: flex;
    padding: 1em;
}
.social-media-icons .icon {
    font-size: 20px;
    background: white;
    color: #151515;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    cursor: pointer;
    margin-left: 10px;
  }
  .social-media-icons .icon:hover{
    background: #0d6efd;
    border: none;
    border-radius: 12px 0 12px 0;
}

@media only screen and (max-width: 768px) {
    .social-media-icons{
        margin: 0 0 2em 0 ;
    }
    .news-letter{
        text-align: center;
    }
}


