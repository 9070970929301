#about {
  padding: 4em;
  background-color: white;
}
.aboutus {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.about-content {
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all ease-in-out 0.3s;
  padding: 1em;
}
.about-content:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(10px);
}

@media only screen and (max-width: 768px) {
  #about {
    padding: 1em;
  }
}

.about-content-title {
  border-radius: 5px;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

/*Profile Card */
.profile-card {
  font-family: "Open Sans", Arial, sans-serif;
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  text-align: center;
  height: 368px;
  border: none;
}
.profile-card .background-block {
  float: left;
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.profile-card .background-block .background {
  width: 100%;
  vertical-align: top;
  opacity: 0.9;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  -webkit-transform: scale(1.8);
  transform: scale(2.8);
}
.profile-card .card-content {
  width: 100%;
  padding: 15px 25px;
  color: #232323;
  float: left;
  background: #efefef;
  height: 50%;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 9999;
}
.profile-card .card-content::before {
  content: "";
  background: #efefef;
  width: 120%;
  height: 100%;
  left: 11px;
  bottom: 51px;
  position: absolute;
  z-index: -1;
  transform: rotate(-12deg);
}
.profile-card .profile {
  border-radius: 64% 36% 50% 50% / 30% 36% 64% 70%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  max-width: 100px;
  opacity: 1;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  border: 2px solid #d73a1e;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  z-index: 99999;
}
.profile-card h2 {
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 25px;
}
.profile-card h2 span {
  display: block;
  font-size: 12px;
  margin-top: 8px;
}
.profile-card h2 small {
  display: block;
  font-size: 15px;
  margin-top: 10px;
}
.profile-card a {
  display: inline-block;
  font-size: 16px;
  color: #232323;
  text-align: center;
  border: 1px solid #232323;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}
.profile-card .icon-block {
  float: left;
  width: 100%;
  margin-top: 15px;
}
.profile-card a:hover {
  background-color: #232323;
  color: #fff;
  text-decoration: none;
}


.aboutus-container {
  width: 100%;
  max-width: 1200px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 2px -2px gray
}
.accordion:focus{
  border: none;
}
.accordion-button {
  padding: 15px;
  text-align: left;
  background-color: white;
  color: black;
  transition: background-color 0.3s ease;
  font-weight: bold;
  font-variant: small-caps;
  font-size: 22px;
  box-shadow: none;
}
.accordion-button:not(.collapsed){
  background-color: white;
  color: #0d6efd;
}
.accordion-button:focus{
  box-shadow: none;
}
.accordion-body {
  padding: 15px;
  border-top: 1px solid #ddd;
}




.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.image-container img {
  max-width: 100%;
  border-radius: 8px;
} 
