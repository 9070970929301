/* Navigation */
.logo {
  width: 100px;
  height: 60px;
}
.navbar{
  z-index: 999999;
}
.nav-link {
  font-weight: 600;
  font-size: 16px;
  margin-left: 15px;
}
.nav-link.active,
.nav-link.focus {
  color: #e44d26 !important;
  font-weight: bolder;
}

.navbar .mx-auto{
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .hire-with-us {
    margin-left: 0px;
  }
  .navbar .mx-auto{
    margin-top: 1em;
    justify-content: start;
    align-items: start;
  }
}

.navbar-social-icon{
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  color: black;
  font-weight: bold;
}


.navbar-social-icon:hover svg{
  color:#e44d26;
  filter: drop-shadow(0 0 5px white) drop-shadow(0 0 5px white)
  drop-shadow(0 0 15px white);
}
/* Navigation */
